import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button, Form, Input } from 'antd';
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  actionDimensionIds,
  AUTH_STATUS,
  CACHE_REVALIDATION_TIME,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME,
  ROUTES,
  SIGNUP_METHOD,
  STATIC_DATA_KEYS,
  staticUiLabels,
  TRACK_VALUE
} from '../common/constants';
import useDeviceInfo from '../common/useDeviceInfo';
import useNetworkType from '../common/useNetworkInfo';
import {
  decode,
  getUserCommonDimensions,
  staticDataApi
} from '../common/utils';
import CustomAnchor from '../components/CustomAnchor';
import { toast } from '../lib/apollo';
import { gaEvent } from '../lib/gtag';

const initialValues = {
  email: '',
  password: ''
};

function Login({
  uiLabels: { LOGIN_FORM_TITLE, LOGIN_FORM_DESCRIPTION } = {}
}) {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { trackEvent } = useMatomo();
  const device = useDeviceInfo();
  const networkType = useNetworkType();
  const [form] = Form.useForm();
  const { data, status } = useSession();

  const { q, email, ...restQuery } = router?.query;
  const decodeQueryParams = decode(q);
  const redirectURL = router.query?.redirectURL;
  const category = MATOMO_CATEGORY.AUTH;
  const action = MATOMO_ACTION.CLICK;
  const formContentDimension = () => {
    const userDimensions = getUserCommonDimensions(
      data?.user,
      device,
      networkType
    );
    const contentObjectForVideo = [
      { id: actionDimensionIds.user_signup_method, value: SIGNUP_METHOD.EMAIL },
      ...userDimensions
    ];
    return contentObjectForVideo;
  };

  useEffect(() => {
    if (router.isReady && email) {
      form.setFieldValue('email', email);
    }
  }, [router, email, form]);

  useEffect(() => {
    if (status === AUTH_STATUS.AUTHENTICATED) {
      trackEvent({
        category,
        action,
        name: MATOMO_NAME.USER_LOGIN,
        customDimensions: formContentDimension(),
        value: TRACK_VALUE.SUCCESS
      });
      gaEvent({
        category,
        action,
        name: JSON.stringify({ userId: data?.user?.id }),
        value: TRACK_VALUE.SUCCESS
      });
      if (redirectURL) {
        window.location.href = decodeURIComponent(redirectURL);
        return;
      }
      if (decodeQueryParams) {
        if (restQuery?.redirectPath === ROUTES?.JOIN) {
          router.replace(ROUTES.HOME);
        } else if (
          data?.user?.currentSubscriptionPlan?.id !== null &&
          restQuery?.redirectPath !== ROUTES?.JOIN
        ) {
          router.back();
          return;
        } else {
          router.push({
            pathname: decodeQueryParams,
            query: restQuery
          });
        }

        return;
      }
      router.replace(process.env.NEXT_APP_SUCCESSFUL_SUBSCRIPTION_REDIRECT_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, data, decodeQueryParams, router, trackEvent, gaEvent]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const authRes = await signIn('credentials', {
        ...values,
        device: JSON.stringify(device),
        redirect: false
      });

      if (authRes?.error) {
        const { message } = JSON.parse(authRes?.error);
        toast({ message, type: 'error' });
        trackEvent({
          category,
          action,
          name: MATOMO_NAME.USER_LOGIN_FAILED,
          customDimensions: formContentDimension(),
          value: TRACK_VALUE.SUCCESS
        });
        gaEvent({
          category,
          action,
          value: TRACK_VALUE.SUCCESS
        });
      }
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="login-wrapper">
      <div className="container">
        <div className="login-block">
          <div className="login-title text-center mb-32">
            <h3 className="display-h3 mb-4">
              {LOGIN_FORM_TITLE?.value ?? staticUiLabels.LOGIN_FORM_TITLE}
            </h3>
            <p>
              {LOGIN_FORM_DESCRIPTION?.value ??
                staticUiLabels.LOGIN_FORM_DESCRIPTION}
            </p>
          </div>

          <Form
            initialValues={initialValues}
            onFinish={onFinish}
            layout="vertical"
            form={form}
          >
            <div className="form-group">
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address!'
                  }
                ]}
                normalize={(value) => value && value.toLowerCase()}
              >
                <Input type="email" />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: 'Please input your password!' }
                ]}
              >
                <Input.Password type="password" />
              </Form.Item>
              <div className="forget-pwd">
                <CustomAnchor href={ROUTES.FORGOT_PASSWORD}>
                  Forgot Password?
                </CustomAnchor>
              </div>
            </div>
            <div className="login-btn">
              <Form.Item>
                <Button
                  className="cs-button"
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={loading}
                >
                  Login
                </Button>
              </Form.Item>
            </div>
            <div className="login-policy text-center text-white-90">
              By selecting Log in, you agree to our{' '}
              <CustomAnchor href={ROUTES.TERMS_AND_CONDITION}>
                Terms
              </CustomAnchor>{' '}
              and have read and acknowledge our{' '}
              <CustomAnchor href={ROUTES.PRIVACY_POLICY}>
                Privacy Policy
              </CustomAnchor>
              .
            </div>
            <div className="login-footer text-black-90">
              Don’t have an account?{' '}
              <CustomAnchor
                href={{
                  pathname: ROUTES.SIGN_UP,
                  query: router.query
                }}
              >
                Create an account
              </CustomAnchor>
            </div>
          </Form>
        </div>
      </div>
    </section>
  );
}

export async function getStaticProps() {
  const configs = {
    revalidate: CACHE_REVALIDATION_TIME
  };

  try {
    const { data: uiLabels } = await staticDataApi?.get(
      `/${STATIC_DATA_KEYS.UI_LABELS}/en.json`
    );
    return {
      props: { uiLabels },
      ...configs
    };
  } catch (error) {
    return {
      props: {},
      ...configs
    };
  }
}

export default Login;
